<template>
  <v-data-table
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="goodsDeliveryStatusRequest.value === 'loading-getGoodsDeliveries'"
    loading-text="Đang tải dữ liệu"
    :items="goodsDeliveries"
    item-key="id"
    @click:row="viewDetail($event)"
  >
    <template v-slot:[`item.code`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.code)"
          >
            {{ item.code }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.shipping_supplier`]="{ item }">
      {{
        item.shipping_supplier && item.shipping_supplier.name
          ? item.shipping_supplier.name
          : item.self_shipping === 1
          ? "Thinkpro tự vận chuyển"
          : "Chưa có đơn vị vận chuyển"
      }}
    </template>
    <template v-slot:[`item.nguoinhan_name`]="{ item }">
      {{ getNameReceiving(item) }}
    </template>

    <template v-slot:[`item.fee`]="{ item }">
      {{ item.fee | formatCurrency }}
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <div class="d-flex flex-row align-center">
        <v-chip
          class="font-weight-bold"
          :color="
            item.status === 1
              ? 'green'
              : item.status === -2
              ? 'amber'
              : 'red accent-2'
          "
          small
          outlined
        >
          {{
            item.status === 1
              ? "Thành công"
              : item.status === -2
              ? "Chờ xác nhận"
              : "Đã hủy"
          }}
        </v-chip>

        <template v-if="item.status === -2">
          <v-spacer></v-spacer>

          <v-btn
            class="red lighten-5 mx-3"
            small
            color="red accent-2"
            icon
            @click.stop="cancelGoodsDelivery(item)"
          >
            <v-icon size="18px">mdi-close-outline</v-icon>
          </v-btn>

          <v-btn
            class="green lighten-5"
            small
            color="green"
            icon
            @click.stop="openModalGoodsDeliveryConfirm(item)"
          >
            <v-icon size="18px">mdi-check-outline</v-icon>
          </v-btn>
        </template>
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Mã phiếu chuyển",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Thời gian tạo",
          align: "start",
          sortable: false,
          value: "created_at"
        },
        {
          text: "Chi nhánh chuyển",
          align: "start",
          sortable: false,
          value: "branch_xuat_name"
        },
        {
          text: "Chi nhánh nhận",
          align: "start",
          sortable: false,
          value: "branch_nhap_name"
        },
        {
          text: "Người chuyển",
          align: "start",
          sortable: false,
          value: "created_user_name"
        },
        {
          text: "Người nhận",
          align: "start",
          sortable: false,
          value: "nguoinhan_name"
        },
        {
          text: "Đơn vị vận chuyển",
          align: "start",
          sortable: false,
          value: "shipping_supplier"
        },
        {
          text: "Phí vận chuyển",
          align: "start",
          sortable: false,
          value: "fee"
        },
        {
          text: "Trạng thái",
          align: "start",
          sortable: false,
          value: "status"
        }
      ]
    };
  },
  computed: {
    goodsDeliveries() {
      return this.$store.getters["GOODS_DELIVERY/goodsDeliveries"];
    },
    goodsDeliveryStatusRequest() {
      return this.$store.getters["GOODS_DELIVERY/statusRequest"];
    }
  },
  filters: {
    formatCurrency(value) {
      // Create our number formatter.
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    cancelGoodsDelivery(item) {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Hủy phiếu chuyển hàng <strong>${item.code}</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Tiếp tục",
            onClickHandler: async () => {
              await this.$store.dispatch("GOODS_DELIVERY/cancelGoodsDelivery", {
                id: item.id,
                index: this.getIndexOfGoodDeliveries(item.id)
              });
              if (
                this.goodsDeliveryStatusRequest.value ===
                "success-cancelGoodsDelivery"
              ) {
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã hủy phiếu chuyển hàng"
                  }
                });
              }
            }
          }
        }
      });
    },

    copyToClipboard(str) {
      const el = document.createElement("textarea");

      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã sao chép vào bộ nhớ tạm"
        }
      });
    },

    getIndexOfGoodDeliveries(val) {
      const arr = this.goodsDeliveries.map(item => item.id);

      return arr.indexOf(val);
    },
    getNameReceiving(item) {
      if (item.participants && item.participants.length > 0) {
        let name = "N/A";
        item.participants.map(participant => {
          if (
            participant.role === "RECEIVING" &&
            participant.user &&
            participant.user.name
          )
            name = participant.user.name;
        });
        return name;
      } else return "N/A";
    },

    async viewDetail(item) {
      await this.$store.dispatch(
        "GOODS_DELIVERY/getGoodsDeliveryById",
        item.id
      );

      this.$modal.show({
        name: "modal-goods-delivery",
        payload: {
          index: this.getIndexOfGoodDeliveries(item.id)
        }
      });
    },

    async openModalGoodsDeliveryConfirm(item) {
      await this.$store.dispatch(
        "GOODS_DELIVERY/getGoodsDeliveryById",
        item.id
      );
      this.$modal.show({
        name: "modal-goods-delivery-confirm",
        payload: {
          item: item,
          index: this.getIndexOfGoodDeliveries(item.id)
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
