var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"tp-table-scroll tp-table__row-pointer datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":_vm.goodsDeliveryStatusRequest.value === 'loading-getGoodsDeliveries',"loading-text":"Đang tải dữ liệu","items":_vm.goodsDeliveries,"item-key":"id"},on:{"click:row":function($event){return _vm.viewDetail($event)}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.code)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.code)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])]}},{key:"item.shipping_supplier",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shipping_supplier && item.shipping_supplier.name ? item.shipping_supplier.name : item.self_shipping === 1 ? "Thinkpro tự vận chuyển" : "Chưa có đơn vị vận chuyển")+" ")]}},{key:"item.nguoinhan_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNameReceiving(item))+" ")]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.fee))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row align-center"},[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":item.status === 1
            ? 'green'
            : item.status === -2
            ? 'amber'
            : 'red accent-2',"small":"","outlined":""}},[_vm._v(" "+_vm._s(item.status === 1 ? "Thành công" : item.status === -2 ? "Chờ xác nhận" : "Đã hủy")+" ")]),(item.status === -2)?[_c('v-spacer'),_c('v-btn',{staticClass:"red lighten-5 mx-3",attrs:{"small":"","color":"red accent-2","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.cancelGoodsDelivery(item)}}},[_c('v-icon',{attrs:{"size":"18px"}},[_vm._v("mdi-close-outline")])],1),_c('v-btn',{staticClass:"green lighten-5",attrs:{"small":"","color":"green","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openModalGoodsDeliveryConfirm(item)}}},[_c('v-icon',{attrs:{"size":"18px"}},[_vm._v("mdi-check-outline")])],1)]:_vm._e()],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }