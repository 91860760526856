<template>
  <tp-modal name="modal-goods-delivery-confirm" max-width="480px">
    <v-card slot-scope="props">
      <v-card-title class="">
        Xác nhận phiếu chuyển #{{
          props.payload.item && props.payload.item.code
        }}
      </v-card-title>
      <v-card-text>
        <div>
          Vui lòng kiểm tra đầy đủ tình trạng sản phẩm cũng như thông tin phiếu
          chuyển. Nhập đầy đủ thông tin nhận hàng vào ô bên dưới để tiếp tục:
        </div>
        <v-checkbox
          v-model="goodsDelivery.self_shipping"
          label="ThinkPro tự vận chuyển"
          class="mt-4 mb-0"
          hide-details
          dense
          @change="goodsDelivery.shipping_supplier_id = null"
        ></v-checkbox>
        <!-- Start: Preparing employer -->
        <div class="mt-4">
          <div
            class="d-flex flex-row align-end justify-space-between"
            v-if="goodsDelivery.self_shipping"
          >
            <div class="col-6 font-weight-bold pa-0">
              Đóng gói, vận chuyển:
            </div>
            <div class="col-6 pa-0">
              <v-autocomplete
                v-model="goodsDelivery.prepared_by_id"
                class="text-body-1 mt-0"
                clearable
                dense
                flat
                hide-details
                :items="employeesSameBranchWithUser"
                item-text="name"
                item-value="id"
                no-data-text="Không có dữ liệu"
                placeholder="Chọn nhân viên"
              ></v-autocomplete>
            </div>
          </div>
          <!-- End: Preparing employer -->

          <!-- Start: Km -->
          <div
            class="d-flex flex-row align-center justify-space-between mt-4"
            v-if="goodsDelivery.self_shipping"
          >
            <div class="font-weight-bold">
              Số km
            </div>
            <div class="col-6 text-right pa-0">
              <v-text-field
                v-model="goodsDelivery.distance"
                class="text-body-1"
                clearable
                dense
                hide-details="auto"
                outlined
                placeholder="Số km"
              ></v-text-field>
            </div>
          </div>
          <!-- End: Km -->
        </div>
        <div v-if="!goodsDelivery.self_shipping">
          <div class="mt-4 d-flex flex-row align-center justify-space-between">
            <div class="font-weight-bold">Đơn vị chuyển</div>
            <v-autocomplete
              v-model="goodsDelivery.shipping_supplier_id"
              class="text-body-1 mt-0"
              clearable
              dense
              flat
              hide-details
              :items="suppliersTransport"
              item-text="name"
              item-value="id"
              no-data-text="Không có dữ liệu"
              style="max-width: 240px"
              placeholder="Chọn đơn vị vận chuyển"
              :full-width="true"
            ></v-autocomplete>
          </div>
          <!--          <div-->
          <!--            class="d-flex flex-row align-end justify-space-between mt-4"-->
          <!--            v-if="goodsDelivery.shipping_supplier_id"-->
          <!--          >-->
          <!--            <div class="col-6 font-weight-bold pa-0">-->
          <!--              Nhân viên đóng gói:-->
          <!--            </div>-->
          <!--            <div class="col-6 pa-0 d-flex align-center justify-end">-->
          <!--              <v-autocomplete-->
          <!--                v-model="goodsDelivery.user_sending"-->
          <!--                class="text-body-1 mt-0"-->
          <!--                dense-->
          <!--                flat-->
          <!--                hide-details-->
          <!--                :items="employeesSameBranchWithUser"-->
          <!--                style="max-width: 240px"-->
          <!--                item-text="name"-->
          <!--                item-value="id"-->
          <!--                no-data-text="Không có dữ liệu"-->
          <!--                placeholder="Chọn nhân viên"-->
          <!--              ></v-autocomplete>-->
          <!--            </div>-->
          <!--          </div>-->
          <div
            class="d-flex flex-row align-end justify-space-between mt-4"
            v-if="goodsDelivery.shipping_supplier_id"
          >
            <div class="col-6 font-weight-bold pa-0">
              Nhân viên nhận hàng:
            </div>
            <div class="col-6 pa-0 d-flex align-center justify-end">
              <v-autocomplete
                v-model="goodsDelivery.user_receiving"
                class="text-body-1 mt-0"
                dense
                flat
                hide-details
                :items="employeesSameBranchWithUser"
                style="max-width: 240px"
                item-text="name"
                item-value="id"
                no-data-text="Không có dữ liệu"
                placeholder="Chọn nhân viên"
              ></v-autocomplete>
            </div>
          </div>
          <div class="d-flex flex-row align-center justify-space-between mt-4">
            <div class="font-weight-bold">
              Số tiền
            </div>
            <div class="col-6 text-right pa-0">
              <tp-input-price
                v-model="goodsDelivery.fee"
                custom-class="text-body-1"
                dense
                flat
                hide-details
                outlined
                solo
                plaholder="Giá tiền"
                style="width: 250px"
              ></tp-input-price>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <div class="font-weight-bold d-flex align-center mb-2">
            <span>Ghi chú</span>
          </div>
          <v-textarea
            v-model="receivedNote"
            class="text-body-1 mt-2"
            auto-grow
            hide-details
            placeholder="Nhập ghi chú tại đây"
            outlined
            rows="3"
            single-line
          ></v-textarea>
        </div>
      </v-card-text>

      <v-card-actions class="px-5">
        <v-spacer></v-spacer>
        <v-btn text color="grey darken-1" @click="closeModal()">
          Hủy
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="confirmGoodsDelivery(props.payload.item, props.payload.index)"
        >
          Xác nhận
        </v-btn>
      </v-card-actions>
    </v-card>
  </tp-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { LIST_SUPPLIER_CATEGORIES } from "@/modules/PartnerAndMember/constant";

export default {
  data() {
    return {
      receivedNote: null,
      supplierCategories: LIST_SUPPLIER_CATEGORIES
    };
  },
  computed: {
    ...mapGetters({
      suppliers: "SUPPLIER/allSuppliers"
    }),
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },
    goodsDelivery() {
      return this.$store.getters["GOODS_DELIVERY/goodsDelivery"];
    },
    goodsDeliveryStatusRequest() {
      return this.$store.getters["GOODS_DELIVERY/statusRequest"];
    },
    suppliersTransport() {
      return this.$store.getters["SUPPLIER/suppliers"];
    },
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },
    employeesSameBranchWithUser() {
      let employeesSameBranchWithUsers = [];
      if (
        this.goodsDelivery &&
        this.goodsDelivery.branch_xuat_id &&
        this.employees
      ) {
        this.employees.map(item => {
          if (item.branch_id === this.goodsDelivery.branch_xuat_id)
            employeesSameBranchWithUsers.unshift(item);
        });
        this.employees.map(item => {
          if (item.branch_id !== this.goodsDelivery.branch_xuat_id)
            employeesSameBranchWithUsers.push(item);
        });
        return employeesSameBranchWithUsers;
      } else return this.employees;
    }
  },
  created() {
    this.$store.dispatch(
      "SUPPLIER/getAllSuppliers",
      `?sup_cate_ids=${this.supplierCategories[2].id}`
    );
    if (this.employees && this.employees.length === 0)
      this.$store.dispatch("EMPLOYEE/getAllEmployees");
    this.$store.dispatch("SUPPLIER/getSuppliers", {
      cur_page: 1,
      filter: {
        fromBalance: null,
        fromBuy: null,
        fromDate: null,
        fromReturn: null,
        sup_cate_ids: [3],
        toBalance: null,
        toBuy: null,
        toDate: null,
        toReturn: null
      },
      per_page: 50,
      search: null
    });
  },
  methods: {
    async confirmGoodsDelivery(item, index) {
      const obj = { ...this.goodsDelivery };
      if (
        (obj.self_shipping && !obj.prepared_by_id) ||
        (obj.self_shipping && !obj.distance)
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thiếu trường nhân viên đóng gói vận chuyển hoặc số km"
          }
        });
      } else if (!obj.self_shipping && !obj.shipping_supplier_id) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thiếu trường đơn vị vận chuyển"
          }
        });
      } else if (!obj.self_shipping && !obj.user_receiving) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thiếu trường nhân viên nhận hàng"
          }
        });
      } else {
        // check exist role PACKAGING, SHIPPING in goods delivery
        let dataPacking = {},
          existShipping = false;
        this.goodsDelivery.participants.map(item => {
          if (item.role === "PACKAGING") {
            dataPacking = item;
          }
          if (item.role === "SHIPPING") {
            dataPacking = item;
            existShipping = true;
          }
        });
        // check is Thinkpro shipping is add or edit role PACKAGING
        // and check is not Thinkpro shipping is add or edit role SHIPPING
        if (!obj.self_shipping) {
          // add role người nhận
          const dataSenderRoleReceiving = {
            model_name: "App\\Models\\ChuyenHang",
            model_id: obj.id,
            user_id: obj.user_receiving,
            note: this.receivedNote,
            role: "RECEIVING"
          };
          await this.$store.dispatch(
            "ORDER/addInvoiceParticipant",
            dataSenderRoleReceiving
          );
        } else {
          if (existShipping) {
            // edit role packing
            const dataSender = {
              id: dataPacking.id,
              model_name: "App\\Models\\ChuyenHang",
              model_id: dataPacking.model_id,
              user_id: obj.prepared_by_id,
              note: this.receivedNote,
              role: "SHIPPING",
              value: obj.distance
            };
            await this.$store.dispatch(
              "ORDER/editInvoiceParticipant",
              dataSender
            );
          } else {
            const dataSender = {
              model_name: "App\\Models\\ChuyenHang",
              model_id: obj.id,
              user_id: obj.prepared_by_id,
              role: "SHIPPING",
              note: this.receivedNote,
              value: obj.distance
            };
            await this.$store.dispatch(
              "ORDER/addInvoiceParticipant",
              dataSender
            );
          }
        }
        await this.$store.dispatch("GOODS_DELIVERY/confirmGoodsDelivery", {
          data: {
            id: item.id,
            note_nhan: this.receivedNote,
            fee: obj.fee,
            shipping_supplier_id: item.shipping_supplier_id,
            self_shipping: !!obj.self_shipping
          },
          index: index
        });
      }
      // Alert
      if (
        this.goodsDeliveryStatusRequest.value === "success-confirmGoodsDelivery"
      ) {
        this.closeModal();
        this.goodsDelivery.status = 1;
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã xác nhận chuyển hàng"
          }
        });
      }
    },
    closeModal() {
      this.$modal.hide({ name: "modal-goods-delivery-confirm" });
      this.receivedNote = null;
    }
  }
};
</script>

<style lang="scss" scoped></style>
