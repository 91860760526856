<template>
  <div class="d_flex">
    <!-- Start: Filter area -->
    <div class="px-5">
      <filter-area
        :date-filter="dateFilter"
        :employees-filter="employeesFilter"
        :received-branch-filter="receivedBranchFilter"
        :sent-branch-filter="sentBranchFilter"
        :status-filter="statusFilter"
        @updateDateFilter="updateDateFilter($event)"
        @updateEmployeesFilter="updateEmployeesFilter($event)"
        @updateReceivedBranchFilter="updateReceivedBranchFilter($event)"
        @updateSentBranchFilter="updateSentBranchFilter($event)"
        @updateStatusFilter="updateStatusFilter($event)"
      ></filter-area>
    </div>
    <!-- End: Filter area -->
    <!-- Start: Main area -->
    <div class="flex-grow-1 overflow-hidden">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :selected-show-filter-id="itemPerPage"
        :search-key="searchKey"
        @exportReportFile="exportReportFile"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @updateSearchKey="updateSearchKey($event)"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-items></data-table-items>
      </div>
      <!-- End: Data table -->
    </div>
    <!-- End: Main area -->
    <!-- Start: Modal -->
    <modal-goods-delivery-confirm></modal-goods-delivery-confirm>
    <!-- End: Modal -->
  </div>
</template>

<script>
import DataTableItems from "./components/DataTableItems";
import FilterArea from "./components/FilterArea";
import ModalGoodsDeliveryConfirm from "./components/modal/ModalGoodsDeliveryConfirm";
import TopAction from "./components/TopAction";
import { exportExcelFile } from "@/core/composables";

export default {
  components: {
    DataTableItems,
    FilterArea,
    ModalGoodsDeliveryConfirm,
    TopAction
  },

  data() {
    return {
      currentPage: 1,
      dateFilter: {
        value: null,
        type: 1
      },
      employeesFilter: [],
      itemPerPage: 50,
      receivedBranchFilter: null,
      sentBranchFilter: null,
      searchKey: null,
      statusFilter: null,
      isLoading: false
    };
  },

  watch: {
    "$route.query"(val) {
      this.getGoodsDeliveriesFromRouteQuery(val);
    }
  },

  async created() {
    const route = this.$route;

    await this.getGoodsDeliveriesFromRouteQuery(route.query);
  },
  methods: {
    async exportReportFile() {
      await exportExcelFile(this, {
        storeName: "GOODS_DELIVERY",
        payload: {
          fromBranch: this.sentBranchFilter,
          toBranch: this.receivedBranchFilter,
          status: this.statusFilter,
          employeeIds: this.employeesFilter,
          fromDate: this.dateFilter.value ? this.dateFilter.value[0] : null,
          toDate: this.dateFilter.value ? this.dateFilter.value[1] : null
        },
        fileName: "bao-cao-chuyen-hang",
        isCheckExportTime: true
      });
    },

    async pushRouter() {
      await this.$router.push({
        name: "transaction_goods-deliveries",
        query: {
          sent_branch_id: this.sentBranchFilter
            ? this.sentBranchFilter
            : undefined,
          received_branch_id: this.receivedBranchFilter
            ? this.receivedBranchFilter
            : undefined,
          created_by_id:
            this.employeesFilter && this.employeesFilter.length > 0
              ? this.employeesFilter
              : undefined,
          status:
            this.statusFilter && this.statusFilter !== "all"
              ? this.statusFilter
              : undefined,
          date_type:
            this.dateFilter.value &&
            this.dateFilter.value[0] &&
            this.dateFilter.type
              ? this.dateFilter.type
              : undefined,
          date_from:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[0]
              : undefined,
          date_to:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[1]
              : undefined,
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    async getGoodsDeliveriesFromRouteQuery(query) {
      if (query.page) {
        // Set current page
        this.currentPage = parseInt(query.page);
        // Set filtered date
        this.dateFilter = query.date_from
          ? {
              value: [query.date_from, query.date_to],
              type: parseInt(query.date_type)
            }
          : { value: null, type: 1 };
        // Set filtered branches
        this.employeesFilter =
          typeof query.created_by_id === "string"
            ? [parseInt(query.created_by_id)]
            : typeof query.created_by_id === "object"
            ? query.created_by_id.map(item => parseInt(item))
            : [];
        // Set item per page
        this.itemPerPage = parseInt(query.limit);
        // Set filtered received branch
        this.receivedBranchFilter = query.received_branch_id
          ? parseInt(query.received_branch_id)
          : null;
        // Set filtered received branch
        this.sentBranchFilter = query.sent_branch_id
          ? parseInt(query.sent_branch_id)
          : null;
        // Set search key
        this.searchKey = query.search || null;
        // Set filtered status
        this.statusFilter = query.status ? parseInt(query.status) : "all";

        // Get products
        await this.$store.dispatch("GOODS_DELIVERY/getGoodsDeliveries", {
          filter: {
            branch_xuat_id: this.sentBranchFilter
              ? [this.sentBranchFilter]
              : null,
            branch_nhap_id: this.receivedBranchFilter
              ? [this.receivedBranchFilter]
              : null,
            created_by_id: this.employeesFilter,
            status:
              this.statusFilter && this.statusFilter !== "all"
                ? [this.statusFilter]
                : null,
            fromDate:
              this.dateFilter && this.dateFilter.value
                ? this.dateFilter.value[0]
                : null,
            toDate:
              this.dateFilter && this.dateFilter.value
                ? this.dateFilter.value[1]
                : null
          },
          search: this.searchKey,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateDateFilter(val) {
      this.dateFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateEmployeesFilter(val) {
      this.employeesFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateReceivedBranchFilter(val) {
      this.receivedBranchFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateSentBranchFilter(val) {
      this.sentBranchFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateStatusFilter(val) {
      this.statusFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>

<style lang="scss" scoped></style>
